<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
      alt="misc-mask"
    />

    <!-- <v-img class="misc-tree" src="@/assets/images/misc/tree-2.png" alt="misc-tree" /> -->

    <div class="page-title text-center px-5">
      <h2 class="text-2xl font-weight-semibold text--primary">
        {{ t('miscellaneous.you_are_not_authorized') }}! 🔐
      </h2>
      <p class="text-sm">
        {{ t('miscellaneous.you_dont_have_permission') }}!
      </p>

      <div class="misc-character d-flex justify-center">
        <v-img
          max-width="100"
          :src="appLogo"
          alt="misc-character"
        />
      </div>

      <v-btn
        color="secondary"
        class="mb-4"
        @click="onBackToHome"
      >
        {{ t('miscellaneous.back_to_home') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { useUtils } from '@core/libs/i18n'
import { openTab } from '@core/utils'
import themeConfig from '@themeConfig'
import store from '@/store'

export default {
  setup() {
    const { t } = useUtils()

    const onBackToHome = () => {
      store.dispatch('auth/logoutAction')
      openTab('https://www.fanaty.com/')
    }

    return {
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      t,
      onBackToHome,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
